<script setup lang="ts">
if (import.meta.client) {
  addEventListener("beforeinstallprompt", (e: Event) => {
    e.preventDefault();
  });
}
</script>

<template>
  <slot />
</template>
